@import '~app/mixins';

.buttonRehabCalculator {
  width: 160px !important;
  display: flex;
  padding: 16px 8px 16px 15px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 50px;
  background: $color-teal !important;

  svg {
    fill: white;
    height: 14px;
    width: 14px;
    margin-right: 4px;
  }

  .iconRehabCalculator {
    width: 10px;
    height: 14px;
    position: relative;
    top: 2px;
    margin-right: 6px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-top: -15px;
  padding-bottom: 15px;
  height: 50px;
}

.headerTitle {
  color: $color-blue-dark;
  font-size: 16px;
  line-height: 18px;
  padding-top: 10px;

  @include text-overflow;
}

.modalBody {
  margin-left: -15px;
  margin-right: 15px;
  width: calc(100% + 30px);
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 90;

  &.hidden {
    display: none;
  }

  svg {
    width: 100px;
    height: 100px;

    use {
      fill: #5394f5;
    }
  }
}

.iframe {
  width: 100%;
  height: 80vh;
  position: relative;
  z-index: 100;

  &.hidden {
    visibility: hidden;
  }
}
